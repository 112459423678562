import React from "react";
import { Link, StaticQuery, graphql } from "gatsby";
import Img from "gatsby-image"
import PropTypes from "prop-types"
import Html from "../components/html"

const Features = ({ items }) => {

  return (
    <div className="mt-12 grid gap-5 max-w-lg mx-auto lg:grid-cols-3 lg:max-w-none">
      {items.map((feature) => (
        <div
          className="flex flex-col rounded-lg shadow-lg overflow-hidden"
          key={feature.title}
        >
          <div className="flex-shrink-0">
            <Link to={feature.link} className="block">
              <Img
                className="h-56 w-full object-cover"
                fluid={feature.image.childImageSharp.fluid}
                alt={feature.title}
              />
            </Link>
          </div>
          <div className="flex-1 bg-white p-6 flex flex-col justify-between">
            <div className="flex-1">
              <Link to={feature.link} className="block">
                <h3 className="mt-2 text-xl leading-7 font-semibold text-gray-900">
                  {feature.title}
                </h3>
                <Html
                  className="mt-3 text-base leading-6 text-gray-500"
                  content={feature.text}
                />
              </Link>
            </div>
            <div className="mt-6 flex items-center justify-center">
              <div className="inline-flex rounded-md shadow mb-1">
                <Link
                  to={feature.link}
                  title="En savoir plus"
                  className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base leading-6 font-medium rounded-md text-white bg-red-600 hover:bg-red-500 focus:outline-none focus:shadow-outline transition duration-150 ease-in-out"
                >
                  <svg
                    className="h-6 w-6 pr-1 inline-block"
                    fill="none"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                  >
                    <path d="M13 16h-1v-4h-1m1-4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                  </svg>{" "}
                  En savoir plus
                </Link>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
}

Features.propTypes = {
  title: PropTypes.string,
  items: PropTypes.shape({
    image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    text: PropTypes.string,
    title: PropTypes.string,
    link: PropTypes.string,
  }),
};

export default Features;